import { connect } from 'react-redux';
import FormEditEvent from './FormEditEvent';
import {
  editEvent, getImagesEvents, setDataEditEvent
} from '../../store/event/actions';

const mapStateToProps = ({
   eventReducer
 }) => ({
  dataSelectEvent: eventReducer.dataSelectEvent,
  images: eventReducer.images
});
const mapDispatchToProps = {
  editEvent,
  setDataEditEvent,
  getImagesEvents
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormEditEvent);
