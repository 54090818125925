import * as actionsTypes from './types';
import { GET_IMAGES_EVENTS_FAIL } from './types';

export function getListEventVotes(payload) {
  return {
    type: actionsTypes.GET_LIST_EVENT_VOTES,
    payload
  };
}
export function getListEventVotesSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_EVENT_VOTES_SUCCESS,
    payload
  };
}

export function getDataEventVote(payload) {
  return {
    type: actionsTypes.GET_DATA_EVENT_VOTE,
    payload
  };
}
export function getDataEventVoteSuccess(payload) {
  return {
    type: actionsTypes.GET_DATA_EVENT_VOTE_SUCCESS,
    payload
  };
}

export function getDataCandidatesOnOrganizationEventVote(payload) {
  return {
    type: actionsTypes.GET_DATA_CANDIDATES_ON_ORGANIZATION_EVENT_VOTE,
    payload
  };
}
export function getDataCandidatesOnOrganizationEventVoteSuccess(payload) {
  return {
    type: actionsTypes.GET_DATA_CANDIDATES_ON_ORGANIZATION_EVENT_VOTE_SUCCESS,
    payload
  };
}

export function getDataCandidatesEventVoteUuid(payload) {
  return {
    type: actionsTypes.GET_DATA_CANDIDATES_EVENT_VOTE_UUID,
    payload
  };
}
export function getDataCandidatesEventVoteUuidSuccess(payload) {
  return {
    type: actionsTypes.GET_DATA_CANDIDATES_EVENT_VOTE_UUID_SUCCESS,
    payload
  };
}

export function setSelectEvent(payload) {
  return {
    type: actionsTypes.SET_SELECT_EVENT,
    payload
  };
}
export function setSelectEventOrganization(payload) {
  return {
    type: actionsTypes.SET_SELECT_EVENT_ORGANIZATION,
    payload
  };
}

export function createEvent(payload) {
  return {
    type: actionsTypes.CREATE_EVENT,
    payload
  };
}

export function deleteEvent(payload) {
  return {
    type: actionsTypes.DELETE_EVENT,
    payload
  };
}

export function editEvent(payload) {
  return {
    type: actionsTypes.EDIT_EVENT,
    payload
  };
}

export function setDataEditEvent(payload) {
  return {
    type: actionsTypes.SET_DATA_EDIT_EVENT,
    payload
  };
}

export function getPushEvent(payload) {
  return {
    type: actionsTypes.GET_PUSH_EVENT,
    payload
  };
}
export function getPushEventSuccess(payload) {
  return {
    type: actionsTypes.GET_PUSH_EVENT_SUCCESS,
    payload
  };
}
export function editPushEvent(payload) {
  return {
    type: actionsTypes.EDIT_PUSH_EVENT,
    payload
  };
}

export function deleteUserOnEvent(payload) {
  return {
    type: actionsTypes.DELETE_USER_ON_EVENT,
    payload
  };
}

export function getListEventReports(payload) {
  return {
    type: actionsTypes.GET_LIST_EVENT_REPORTS,
    payload
  };
}
export function getListEventReportsSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_EVENT_REPORTS_SUCCESS,
    payload
  };
}
export function getListEventReportsFail(payload) {
  return {
    type: actionsTypes.GET_LIST_EVENT_REPORTS_FAIL,
    payload
  };
}

export function getCreateEventReport(payload) {
  return {
    type: actionsTypes.GET_CREATE_EVENT_REPORT,
    payload
  };
}

export function getStaticEvent(payload) {
  return {
    type: actionsTypes.GET_STATIC_EVENT,
    payload
  };
}
export function getStaticEventSuccess(payload) {
  return {
    type: actionsTypes.GET_STATIC_EVENT_SUCCESS,
    payload
  };
}
export function getStaticEventFail(payload) {
  return {
    type: actionsTypes.GET_STATIC_EVENT_FAIL,
    payload
  };
}
export function getImagesEvents() {
  return {
    type: actionsTypes.GET_IMAGES_EVENTS
  };
}
export function getImagesEventsSuccess(payload) {
  return {
    type: actionsTypes.GET_IMAGES_EVENTS_SUCCESS,
    payload
  };
}
export function getImagesEventsFail(payload) {
  return {
    type: actionsTypes.GET_IMAGES_EVENTS_FAIL,
    payload
  };
}
