import React, {
  useCallback,
  useEffect, useRef, useState
} from 'react';
import {
  Col,
  Form,
  Input,
  Row
} from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import { IMAGES_EVENTS } from '../../utils/url';

const FormEditEvent = ({
 dataSelectEvent, setDataEditEvent, getImagesEvents, images
}) => {
  const [form] = Form.useForm();
  const [swiper, setSwiper] = useState(null);
  const [text, setText] = useState('');
  const setEventText = useCallback((eventText) => {
    const sliced = eventText.slice(0, 2048);
    setText(sliced);
    setDataEditEvent({ text: sliced });
    form.setFieldsValue({
      text: sliced ?? ''
    });
  }, [setText]);

  SwiperCore.use([Navigation, Pagination, EffectCoverflow]);
  useEffect(() => {
    getImagesEvents();
  }, [getImagesEvents]);
  useEffect(() => {
    if (dataSelectEvent.uuid) {
      form.setFieldsValue({
        uuid: dataSelectEvent.uuid,
        text: dataSelectEvent.text
      });
      setText(dataSelectEvent.text);
    }
  }, [form, dataSelectEvent.text, dataSelectEvent.uuid, dataSelectEvent.imageUrl]);
  useEffect(() => {
    if (images) {
      swiper.slideTo(images?.files?.findIndex((img) => dataSelectEvent.imageUrl?.includes(img)));
    }
  }, [images, dataSelectEvent.imageUrl, swiper]);
  return (
    <Form name="basic"
        layout="vertical"
        form={form}
        autoComplete="off"
        requiredMark={false}
  >
      <Row gutter={[40, 8]}>
        <Col md={24} lg={12}>
          <Form.Item label={`Описание мероприятия (${text?.length ?? 0} / 2048)`}
                   name="text"
                   rules={[
                     {
                       required: true,
                       message: 'Введите описание мероприятия'
                     }
                   ]}
        >
            <Input.TextArea
              style={{ height: '250px' }}
              onChange={(event) => setEventText(event.target.value)}
            />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item label="Картинка мероприятия"
                   name="imageUrl"
                   rules={[
                     {
                       required: true
                     }
                   ]}
        >
            <Swiper
            onSwiper={setSwiper}
            tag={'section'}
            navigation
            pagination={{ clickable: true }}
            effect="coverflow"
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false
            }}
            slidesPerView={2}
            centeredSlides
            style={{
              height: '250px',
              backgroundColor: '#EEEEEE',
              padding: '10px'
            }}
            initialSlide={images?.files?.findIndex((img) => dataSelectEvent.imageUrl?.includes(img))}
            onSlideChange={(event) => {
              const img = images?.files[event.activeIndex];
              if (!dataSelectEvent.imageUrl?.includes(img)) {
                setDataEditEvent({ imageUrl: `${IMAGES_EVENTS}${img}` });
              }
            }}
          >
              {images?.files?.map((img, i) => (
                <SwiperSlide
                key={img}
                style={{
                  backgroundImage:
                    `url(${IMAGES_EVENTS}${img})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundColor: '#FFFFFF'
                }}
              />
))}
            </Swiper>
          </Form.Item>
        </Col>
      </Row>
    </Form>
);
};

export default FormEditEvent;
